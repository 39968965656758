import React from "react";
import Container from "../../components/container/container";
import PostBody from "../../components/post/body";
import PostHeader from "../../components/post/header";
import style from "./blog.mod.scss"


export default function BlogArticle({ post }) {
  return (
    <Container gutters pt={2} pb={2} width="large">
        <article className={style.blogArticle}>
            <PostHeader
                title={post.title}
                coverImage={post.coverImage}
                date={post.date}
                author={post.author}
            />
            <PostBody content={post.content} />
        </article>
    </Container>
  );
}