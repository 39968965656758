import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { StructuredText } from "react-datocms";
import style from "./post.mod.scss"

export default function PostBody({ content }) {
  return (
    <div className={style.post__body}>
      <StructuredText
        data={content}
        renderBlock={({ record }) => {
          if (record.__typename === "DatoCmsImageBlock") {
            return (
              <div className={style.post__body__image}>
                <GatsbyImage alt={""} image={record.image.gatsbyImageData} />
              </div>
            );
          }
          return (
            <>
              <p>Don't know how to render a block!</p>
              <pre>{JSON.stringify(record, null, 2)}</pre>
            </>
          );
        }}
      />
    </div>
  );
}