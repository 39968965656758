import React from "react";
import Avatar from "./avatar";
import Date from "./date";
import CoverImage from "./cover-image";
import PostTitle from "./title";
import style from "./post.mod.scss"


export default function PostHeader({ title, coverImage, date, author }) {
  return (
    <>
      <PostTitle>{title}</PostTitle>
      <div className={style.post__header__meta}>
        <div>
          <Avatar name={author?.name} picture={author?.picture} />
        </div>
        <div>
          <Date dateString={date} />
        </div>
      </div>
      <div>
        <CoverImage title={title} fluid={coverImage?.gatsbyImageData} />
      </div>
    </>
  );
}