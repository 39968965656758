import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/global/layout";
import Section from "../../components/global/section";
import Contact from "../../sections/contact/contact";
import Footer from "../../components/footer/footer";
import BlogArticle from "../../sections/blog/blog-article"
import BlogPosts from "../../sections/blog/blog-posts"

export default function Post({ 
    location,
    data: { 
        datoCmsHomeSeo,
        datoCmsSite,
        footer,
        post,
        allPosts,
    }
}) {
  const nextPosts = { nodes: allPosts.nodes.filter(p => (p.date <= post.date) && (p.slug !== post.slug)) }

  return (
    <>
      <Layout
        seo={datoCmsHomeSeo.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
        location={location}
      >
        <Section gradientAlt id={"post"}>
          <BlogArticle post={post} />
        </Section>
        { nextPosts && nextPosts.nodes.length > 0 &&
          <Section gradient id={"nextPosts"}>
            <BlogPosts allPosts={nextPosts} title={"More posts"} />
          </Section>
        }
        <Section gradient id={"contact"}>
          <Contact />
          <Footer {...footer} />
        </Section>
      </Layout>
    </>
  );
}

export const query = graphql`
  query PostBySlug($id: String) {
    datoCmsHomeSeo {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
    }
    datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    post: datoCmsPost(id: { eq: $id }) {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      content {
        value
        blocks {
          __typename
          id: originalId
          image {
            gatsbyImageData(width: 700)
          }
        }
      }
      date
      coverImage {
        gatsbyImageData(width: 2000)
        small: gatsbyImageData(aspectRatio: 1.7, width: 760)

      }
      author {
        name
        picture {
          gatsbyImageData(
            layout: FIXED
            width: 48
            height: 48
            imgixParams: { sat: -100 }
          )
        }
      }
    }
    allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug
        excerpt
        date
        coverImage {
          small: gatsbyImageData(aspectRatio: 1.7, width: 760)
        }
      }
    }
    footer: datoCmsFooterSection {
      heading
      links {
        ...Link
      }
      address
      credit
      email
      linkedin
      url
      urlText
      privacy {
        url
        title
      }
      terms {
        url
        title
      }
    }
  }
`;
