import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import style from "./post.mod.scss"

export default function Avatar({ name, picture }) {
  return (
    <div className={style.post__avatar}>
      <GatsbyImage
        className={style.post__avatar__image}
        image={picture.gatsbyImageData}
        alt={`Photo of ${name}`}
      />
      <div className={style.post__avatar__name}>{name}</div>
    </div>
  );
}